import { Container, TitleWithBackButton } from '@/components';
import { COLORS, PADDINGS, REGEXPS, TYPOGRAPHY } from '@/constants';
import { Button, InputWithLabel, PasswordInput } from '@/ui/components';
import React, { FC } from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorTexts } from '@/types';
import { useAppDispatch } from '@/store/hooks';
import { signUpThunk } from '@/store/ducks/auth/thunks';
import dayjs from 'dayjs';

interface FormData {
  firstName: 'string';
  lastName: 'string';
  mobileNumber: 'string';
  email: 'string';
  password: 'string';
  passwordConfirmation: 'string';
}

const schema = yup
  .object({
    firstName: yup.string().required(ErrorTexts.required),
    lastName: yup.string().required(ErrorTexts.required),
    mobileNumber: yup
      .string()
      .matches(REGEXPS.phoneRegExp, ErrorTexts.incerrectPhoneNumber)
      .required(ErrorTexts.required),
    email: yup.string().email(ErrorTexts.email).required(ErrorTexts.required),
    password: yup
      .string()
      .min(6, ErrorTexts.lengthPassword)
      .required(ErrorTexts.required),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], ErrorTexts.confirmPassword)
      .required(),
  })
  .required();

const SignUp: FC = () => {
  const dispatch = useAppDispatch();
  const { file } = useStaticQuery(graphql`
    query PersonalDataQuery {
      file(name: { eq: "Согласие-о-персданых" }) {
        id
        publicURL
      }
    }
  `);

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors, isSubmitting, isValid, isDirty },
    getFieldState,
  } = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const isSuccessField = (field: keyof FormData) =>
    getFieldState(field, formState).isDirty && !errors[field];

  const onSubmit = handleSubmit(async (data) => {
    const { passwordConfirmation, ...restData } = data;
    const neededData = {
      ...restData,
    };

    try {
      const result = await dispatch(signUpThunk(neededData)).unwrap();
      if (result) {
        navigate('/profile');
      }
    } catch (rejectedValueOrSerializedError) {}
  });

  return (
    <Root>
      <StyledContainer>
        <Wrapper>
          <StyledTitleWithBackButton text="Регистрация" />
          <form onSubmit={onSubmit}>
            <Inputs>
              <StyledInputWithLabel
                label="Фамилия"
                name="lastName"
                placeholder="Иванов"
                errorMessage={errors.lastName?.message}
                isSuccess={isSuccessField('lastName')}
                {...register('lastName')}
              />
              <StyledInputWithLabel
                label="Имя"
                name="firstName"
                placeholder="Иван"
                errorMessage={errors.firstName?.message}
                isSuccess={isSuccessField('firstName')}
                {...register('firstName')}
              />

              <StyledInputWithLabel
                label="Мобильный телефон"
                name="mobileNumber"
                type="tel"
                placeholder="+7 (903) 485 24-32"
                mask="+7 (999) 999-99-99"
                errorMessage={errors.mobileNumber?.message}
                isSuccess={isSuccessField('mobileNumber')}
                {...register('mobileNumber')}
              />
              <StyledInputWithLabel
                label="E-mail"
                name="email"
                type="email"
                placeholder="E-mail"
                errorMessage={errors.email?.message}
                isSuccess={isSuccessField('email')}
                {...register('email')}
              />
              <InputWithNewRow
                label="Пароль"
                placeholder="Пароль"
                errorMessage={errors.password?.message}
                isSuccess={isSuccessField('password')}
                {...register('password')}
              />
              <StyledPasswordInput
                label="Подтвердить пароль"
                placeholder="Подтвердить пароль"
                errorMessage={errors.passwordConfirmation?.message}
                isSuccess={isSuccessField('passwordConfirmation')}
                {...register('passwordConfirmation')}
              />
            </Inputs>
            <ButtonWrapper>
              <StyledButton
                type="submit"
                disabled={!isDirty || !isValid}
                isLoading={isSubmitting}
              >
                Регистрация
              </StyledButton>
              <PolicyText>
                Нажимая на кнопку, вы даете согласие на обработку
                <a href={file.publicURL} target="_blank">
                  персональных данных
                </a>
              </PolicyText>
            </ButtonWrapper>
          </form>
        </Wrapper>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.grayLighter};
`;

const StyledContainer = styled(Container)`
  padding-top: 48px;
  padding-bottom: ${PADDINGS.paddingBetweenSectionsLarge};
  @media (max-width: 1024px) {
    padding-bottom: 64px;
  }
`;

const Wrapper = styled.div`
  background-color: ${COLORS.white};
  border-radius: 18px;
  padding: 50px 5.63%;
  max-width: 888px;
  margin: 0 auto;
  @media (max-width: 1350px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 1024px) {
    padding: 0;
    background-color: transparent;
  }
`;

const StyledTitleWithBackButton = styled(TitleWithBackButton)`
  margin-bottom: 32px;
`;

const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 32px;
  @media (max-width: 600px) {
    display: block;
  }
`;

const StyledInputWithLabel = styled(InputWithLabel)`
  @media (max-width: 600px) {
    margin-bottom: 24px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledPasswordInput = styled(PasswordInput)`
  @media (max-width: 600px) {
    margin-bottom: 24px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const InputWithNewRow = styled(StyledPasswordInput)`
  grid-column: 1 / auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  @media (max-width: 600px) {
    display: block;
  }
`;

const StyledButton = styled(Button)`
  margin-right: 24px;
  @media (max-width: 600px) {
    margin-bottom: 16px;
    margin-right: 0;
    max-width: none;
  }
`;

const PolicyText = styled.p`
  ${TYPOGRAPHY.caption1Regular14};
  color: ${COLORS.black};
  max-width: 380px;
  a {
    text-decoration: underline;
  }
  @media (max-width: 600px) {
    max-width: none;
  }
`;

export default SignUp;
